import React from "react"
import Log from "./../../../templates/log.js"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { Caption, Warning, FirstP, Extern, Pre, Arduino } from "./../../../components/helpers.js"
import { EEPROM } from "./../../../components/definitions.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <Warning>This post explains the reasoning and philosophy behind the ESP8266 IoT Framework.
            Since the framework is evolving over time, some of this post might be outdated. Find the latest
            on <Extern href="https://github.com/maakbaas/esp8266-iot-framework">GitHub</Extern>.</Warning>

        <FirstP>One crucial component for the ESP8266 IoT framework is a WiFi manager. It is not hard to connect an ESP8266 to a WiFi network with the standard 
            Arduino libraries if you manually enter your credentials in your code. But of course that is not how we want to approach it here. The WiFi manager should 
            allow the user to enter the right WiFi details in the browser, and try to connect to that network automatically.
        </FirstP>

        <p>
            To start with the credits first: there is already a popular WiFi manager out there by <Extern href="https://github.com/tzapu/WiFiManager">tzapu</Extern>. 
            The WiFi code in this class is robust and proven in use, but what I liked less, is that the web interface for managing WiFi credentials is 
            completely integrated into the application.
            Instead, I preferred the web interface to be separate and to communicate with the rest of the application
            through an API, as discussed in my previous <Link to="/esp8266-iot-framework/logs/web-server/">post</Link>. Therefore I decided to take only the WiFi code from the existing class,
            and reshape it to fit my own architecture.</p> 

        <p>I will discuss the implementation with two examples. First I will show what happens when you initialize the application, and secondly I will present the state machine
            for updating the WiFi credentials when the application is running.</p>
        
        <h3>Starting the Application</h3>

        <p>All you need to write in the setup of your program is the following line:</p>

        <Arduino>
            {
                `WiFiManager.begin("your-access-point-name");`
            }
        </Arduino>

        <p>The function argument is the network name that will be used in case a hotspot has to be started by the ESP8266. The flow chart that is implemented by this function is shown below.</p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="WiFiManager Flowchart" />
        <Caption>The flowchart of the WiFiManager.begin function</Caption>

        <p>In short, the WiFi manager tries to connect to existing network details, and if this fails, or these are not available a hotspot will be started. This is implemented as a 
            captive portal so that as soon as you connect to this network from a computer or phone, a web page should pop up where you can enter the WiFi details. This is implemented
            by redirecting all requested URLs automatically to the WiFi page.
        </p>

        <p>One other nice thing I found out is that the WiFi functions from the ESP8266 store the SSID and password in flash automatically. In past implementations I did, I stored these 
            in <EEPROM />, but it turns out this was completely unneccessary. The WiFi details are already inherently persistent between power cycles.</p>

        <h3>Web interface</h3>

        <p>The page for changing the WiFi details is integrated with the rest of the web interface and is served from the default address of 192.168.4.1 in case of a captive portal.</p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="WiFi interface" />
        <Caption>This page shows the current WiFi status and allows you to change the network</Caption>

        <p>If the save button is clicked, the following callback is executed within the web server class:</p>

        <Arduino>
            {
                `//update WiFi details
server.on(PSTR("/api/wifi/set"), HTTP_GET, [](AsyncWebServerRequest *request) 
{
    request->send(200, PSTR("text/html"), ""); //respond first because of wifi change
    WiFiManager.setNewWifi(request->arg("ssid"), request->arg("pass"));
});`
            }
        </Arduino>

        <p>This function sets a flag which is checked in the <Pre>WifiManager.loop</Pre>. This function in turn calls <Pre>WiFiManager.connectNewWiFi</Pre> in which the actual 
        change will be performed. This mechanism is needed to make the WiFi update asynchronous from the <Link to="/esp8266-iot-framework/logs/web-server/">web server</Link> callback.</p>

        <h3>Changing WiFi settings</h3>

        <p>If the WiFi settings are saved from the GUI, <Pre>WiFiManager.connectNewWiFi</Pre> is called to update the WiFi details accordingly. This function
        follows the flow shown in the diagram below.</p>

        <p>This process is quite easy to understand. First the method tries to connect to the new WiFi details. If this fails it checks if it is currently connected
            to another network, or if it is running a hotspot with a captive portal. In this second case it keeps the hotspot and waits for new input. If the connection
            to new details has failed and old details are still known, the WiFi manager reverts to these. If even that fails, again a hotspot is started to capture new user input.
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Change WiFi Flowchart" />
        <Caption>The flowchart of the WiFiManager.connectNewWifi function</Caption>

        <h3>Full Source Code</h3>

        <p>This post only contained some snippets of the code to explain the high level approach that was taken. The full implementation for the ESP8266 IoT
            framework is found on <Extern href="https://github.com/maakbaas/esp8266-iot-framework">GitHub</Extern>. The documentation for the WiFi manager
            can be found <Extern href="https://github.com/maakbaas/esp8266-iot-framework/blob/master/docs/wifi-manager.md">here</Extern>.
        </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "wifi1.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "screenshot-wifi.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "wifi2.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
